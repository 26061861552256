.password_reset_complete{
  margin: 2.5rem 1rem;
  background-color: #FFFFFF;
  border-radius: 0.5rem;
  box-shadow: 0px 1px 1px 0px #8282820A, 0px 4px 8px 0px #00000014;
  padding: 1.5rem 1rem;
  .header{
    width: 100%;
    height: 1.5rem;
    position: relative;
    .back{
      width: 1.5rem;
      height: 1.5rem;
      position: absolute;
      top: 0;
      left: 0;
    }
    .title{
      font-weight: 700;
      font-size: 1rem;
      line-height: 1.5rem;
      color: #333333;
      text-align: center;
    }
  }
  .tip{
    width: 100%;
    margin: 1.5rem auto;
    p{
      font-size: 1rem;
      line-height: 1.5rem;
      color:#333333;
    }
  }
  .btn_area{
    margin-top: 1.5rem;
  }
}