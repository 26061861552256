.share_wrapper{
  position: fixed;
  top: 0;
  left:0;
  width: 100%;
  height: calc(100% - 5rem);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 1rem;
  z-index: 100;
  .share_container{
    background-color: white;
    padding: 2.5rem 1.25rem;
    border-radius: 0.625rem;
    box-shadow: 0 0 0.625rem rgba(0, 0, 0, 0.1);
    width: 100%;
    max-height: 90%;
    max-width: 25rem;
    text-align: center;
    overflow: auto;
    position: relative;
    h1 {
      font-size: 1.5rem;
      margin-bottom: 1.5rem;
    }
    p {
      font-size: 1rem;
      margin-bottom: 1.25rem;
    }
    h3{
      font-size: 1rem;
    }
    .checkbox-group {
      margin-top: 1.25rem;
      text-align: left;
      label {
        display: flex;
        align-items: center;
        margin-top: 10px;
        font-size: 1em;
      }
      p {
        margin-left: 10%;
        margin-bottom: 0;
      }
    }
    .checkbox-title{
      font-size: 1rem;
      margin-top: 1.5rem;
    }
  }
  .share_alert{
    width: 100%;
    max-height: 90%;
    max-width: 20rem;
    text-align: center;
    overflow: auto;
    position: absolute;
    z-index: 100;
    transition: none;
  }
}