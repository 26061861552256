.wrapper{
  padding: 0 1rem;
  height: calc(100vh - 1rem);
  position: relative;
  .header{
    padding: 5rem 0 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    row-gap: 0.625rem;
    .icon {
      width: 9.5rem;
      max-width: 9.5rem;
    }
    .icon_text{
      color: #613DCC;
      font-size: 1.5rem;
      font-weight: 900;
    }
  }
  .login_box{
    background: #FFFFFF;
    border-radius: 0.5rem;
    padding: 0.75rem;
    display: flex;
    flex-direction: column;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    .form_item{
      margin-top: 1.5rem;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      row-gap: 0.25rem;
      position: relative;
      .form_label{
        font-weight: 400;
        font-size: 0.875rem;
        line-height: 1.25rem;
        color: #333333;
      }
      .icon{
        position: absolute;
        right: 0.6rem;
        bottom: 0.8rem;
        cursor: pointer;
      }
    }

    .pass_change_link{
      font-weight: 400;
      color: #613DCC;
      font-size: 0.875rem;
      margin: 1rem 0 1.5rem;
      text-align: right;
      text-decoration: none;
    }
  }
}
