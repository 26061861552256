.form_input{
  height: 3.125rem;
  border-radius: 0.25rem;
  border: 1px solid #D9DAEA;
  padding: 0 0 0 0.875rem ;
  outline-color: #9f55ff;
  font-size: 1rem;
  line-height: 1.25rem;
}
.error{
  font-size: 0.875rem;
  color: red;
}