.home{
  padding: 1rem 1rem 0.25rem;
  .header{
    width: 100%;
    height: 2.875rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    .id{
      color: #333333;
      font-size: 1.5rem;
    }
    .icon{
      display: flex;
      align-items: center;    
      img{
        width: 2.5rem;
        height: 2.5rem;
      }
      .icon_item{
        margin-left: -1.5rem;
      }
      .icon_item_white{
        width: 2.5rem;
        height: 2.5rem;
        border-radius: 2.5rem;
        background-color: #FFFFFF;
      }
    }
    .menu{
      position: absolute;
      width: 10rem;
      z-index: 9999;
      bottom: -2.25rem;
      right: 0;
    }
  }
  .card{
    width: 100%;
    height: 17.125rem;
    border-radius: 0.5rem;
    margin-top: 1rem;
    padding: 0.25rem 1rem;
    background-color: #ffffff;      
    box-shadow: 0px 1px 1px 0px #8282820A, 0px 4px 8px 0px #00000014;
    .title{
      height: 1.875rem;
      border-bottom: 1px solid #D9DAEA;
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      .title_left{
        display: flex;
        align-items: center;
        justify-content: flex-start;
        img{
          width: 1.25rem;
          height: 1.25rem;
          margin-right: 0.375rem;
        }
        span{
          font-size: 0.875rem;
          color: #828282;
        }
      }
    }
    .middle{
      margin-top: 0.375rem;
      height: 9.375rem;
      display: flex;
      align-items: center;
      .info{
        width: 100%;
        height: 100%;
        flex:1;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-around;
        position: relative;
        color: #828282;
        font-size: 0.875rem;
        img{
          margin-left: 0.5rem;
          position: absolute;
          width: 7.5rem;
          z-index: 1;
          opacity: 0.6;
        }
        div{
          z-index: 2;
        }
        .bigger{
          color: #333333;
          font-size: 1.5rem;
        }
      }
      .pic{
        width:7.5rem;
      }
    }
    .bottom{
      margin-top: 0.375rem;
      font-size: 0.875rem;
      color: #333333;
      line-height: 1.25rem;
      position: relative;
      .tip1{
        width: 100%;
      }
      .tip2{
        font-family: 'Hiragino Mincho Pro';
        width: 100%;
        font-weight: 600;
        text-align: right;
        padding-right: 2.5rem;
      }
      .sign{
        width: 2.5rem;
        height: 2.5rem;
        position: absolute;
        right: 0;
        bottom: 0;
      }
    }
  }
  .card.small{
    height: 12rem;
    padding: 1.5rem 1rem;
    .middle{
      height: 2.25rem;
      .info{
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        column-gap: 1rem;
      }
    }
  }
  .widgits{
    margin-top: 0.625rem;
    display: flex;
    flex-direction: column;
    .widgit_item{
      width: 100%;
      height: 7.5rem;
      border-radius: 0.5rem;      
      background-color: #FFFFFF;
      margin-bottom: 0.625rem;
      display: flex;
      align-items: center;
      justify-content: center;
      box-shadow: 0px 0.95px 0.95px 0px #8282820A,0px 3.81px 7.63px 0px #00000014;
    }
    .has_content{
      height: 10rem;
      padding: 0.5rem 0.75rem;
      align-items: flex-start;
      justify-content: flex-start;
      .left{
        width: 4.5rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        img{
          width: 4.5rem;
        }
        .icon{
          width: 1.25rem;
          height: 1.25rem;
        }
        .up{
          margin-top: 1.5rem;
        }
        .content{
          width: 3.75rem;
          height: 1.875rem;
          border-radius: 0.25rem;
          background-color: #4474ED;
          font-size: 1.125rem;
          color: #FFFFFF;
          text-align: center;
          line-height: 1.875rem;
          margin: 0.75rem 0;
        }        
      }
      .right{
        flex:1;
        height: 100%;
        margin-left: 0.625rem;
      }
      .toppan_title{
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        column-gap: 0.25rem;
        margin-bottom: 0.5rem;
        img{
          width: 1rem;
          height: 1rem;
        }
        p{
          color: #000000;
          font-weight: 900;
          font-size: 0.875rem;
          line-height: 1rem;
        }
      }
      .toppan_subject{
        margin-top: 0.25rem;
        display: flex;
        column-gap: 0.25rem;
        .left{
          width: 4.5rem;
          color: #000000;
          font-weight: 900;
          font-size: 0.8rem;
          align-items: end;
        }
        .left.light{
          color:#333333;
          font-weight: 400;          
        }
        .right{
          display: flex;
          align-items: center;
          justify-content: space-between;
          .sub_title{
            width: 3rem;
            height: 1.4rem;
            border-radius: 0.125rem;
            color: #FFFFFF;
            font-weight: 700;
            font-size: 0.875rem;
            line-height: 1.4rem;
            text-align: center;
          }
          .sub_title.red{
            background-color: #E64363;
          }
          .sub_title.blue{
            background-color: #4474ED;
          }
          .sub_title.green{
            background-color: #00A99D;
          }
          .sub_title.yellow{
            background-color: #E8B500;
          }
          .sub_date{
            width: 3rem;            
            color: #828282;
            font-size: 0.75rem;
            font-weight: 400;
            text-align: center;
          }
          .sub_icon{
            width: 3rem; 
            text-align: center;
            img{
              width: 1.4rem;
              height: 1.4rem;
            }
          }
          .sub_point{
            width: 3rem;
            text-align: center;
            color:#1e1e1e;
            font-weight: 700;
            font-size: 2rem;
          }
          .sub_avg_point{
            width: 3rem;
            text-align: center;
            color: #aaaaaa;
            font-weight: 700;
            font-size: 1rem;
          }
        }
      }
      .toppan_subject.aligncenter{
        align-items: center;
      }
    }
    .has_content.has_content_toppan{
      display: block;
    }
  }
}