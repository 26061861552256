.layout{
  font-size: 1rem;
  .container{
    width: 100%;
    position: fixed;
    top: 0;
    bottom: 5rem;
    overflow: auto;
  }
  .footer{
    position: fixed;
    bottom: 0;
    width: 100%;
    padding: 0 1rem;
    .tarbar_bar{
      width: 100%;
      height:3.75rem;
      margin-bottom: -1rem;
      background-color:#FFFFFF;
      padding: 0.5rem 1rem;
      border-radius:5.625rem;
      box-shadow:0px 1px 1px 0px #8282820A ,0px 4px 1rem 0px #00000029;
      display: flex;
      align-items: center;
      justify-content: space-around;
      .tabbar_item{
        display: flex;
        flex-direction: column;
        align-items: center;
        .tabbar_item_icon{
          color: #828282;
          svg{
            width: 1.5rem;
            height: 1.5rem;
          }
        }
        .tabbar_item_text{
          color: #828282;
          font-size: 0.625rem;          
        }
      }
      .tabbar_item_active{
        .tabbar_item_icon{
          color: #613DCC;   
        }
        .tabbar_item_text{
          color: #613DCC;     
        }        
      }
    }
    .tabbar_bottom{
      width: 98%;
      height:1.875rem;
      background-color:transparent;
      position:relative;
      z-index:-2;
      left: 50%;
      transform: translateX(-50%);
    }
  }
}