.card_wrapper{
  position: fixed;
  top: 0;
  left:0;
  width: 100%;
  height: calc(100% - 5rem);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 1rem;
  z-index: 100;
  .cardContainer{
    text-align: center;
    background-color: white;
    padding: 1.25rem;
    border-radius: 0.625rem;
    box-shadow: 0 0 0.625rem rgba(0, 0, 0, 0.1);
    width: 90%;
    max-width: 25rem;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    h1 {
      font-size: 1.5rem;
      margin-bottom: 0.625rem;
    }
    p {
      font-size: 1rem;
      margin-bottom: 1.25rem;
    }
    img {
      width: 12.5rem;
      height: 12.5rem;
      margin-bottom: 1.25rem;
    }
    .info p{
      font-size: 1rem;
      margin: 0.3125rem 0;
    }
  }
}
